/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { FiFilter, FiXSquare } from 'react-icons/fi';
import { FaSave, FaCalendarAlt, FaUserCheck } from 'react-icons/fa';
// import MiniSearch from '../../assets/images/buttons/button-mini-search.svg';
import MiniPrint from '../../assets/images/buttons/button-mini-print.svg';
import MiniFilter from '../../assets/images/buttons/button-mini-filter.svg';
import MiniAdd from '../../assets/images/buttons/button-mini-add.svg';

import Content from '../../components/Content';
import { Container, Row } from '../../components/Form';
import { Flex, Input, ButtonRow, FilterLabel, Select, TextArea } from '../../components/FormRef/Form'; //FilterLabel || Select

// import useList from "./useList";
import Moment from '../../services/Moment';
import { FormContext } from '../../components/FormRef/FormContext';
import { toast } from 'react-toastify';

import Mask from '../../services/mask';
import { ajax, serialize } from '../../api/chequer';

import TableRender from './components/Table';
import TableSector from './components/TableSector';
import Paginate from './components/Paginate';

import { Modal } from '../../components/Modal';
import AuthContext from '../../contexts/auth';
import Utils from '../../services/utils';

export default function PontoList() {
  const history = useHistory();
  const { todayAddDay, converteToTime, weekDay } = Moment();
  const { splitName } = Utils();
  const { fields } = React.useContext(FormContext);
  const { user } = React.useContext(AuthContext);

  const [loading, setLoading] = React.useState(false);
  const [pageActive, setPageActive] = React.useState(1);
  const [dataSet, setDataSet] = React.useState(null);
  const [dataSetSector, setDataSetSector] = React.useState(null);
  const [colaboradores, setColaboradores] = React.useState(null);
  const [isNotUsers, setNotUsers] = React.useState(null);
  const [count, setCount] = React.useState(0);
  const [limitCount, setLimitCount] = React.useState(0);
  const [isModalRegister, setIsModalRegister] = React.useState(false);
  const [isModal, setIsModal] = React.useState(false);
  const [timesheetId, setTimesheetId] = React.useState(null);
  const [buttonSubmit, setButtonSubmit] = React.useState(false);

  //**RENDER SVG ICON */
  function Svg(props) {
    var useTag = `<use xlink:href="/images/sprite.svg#${props.icon}" />`;
    return <svg className={props.className} dangerouslySetInnerHTML={{ __html: useTag }} />;
  }

  //**FUNÇÃO PARA VERIFICAR STRING VAZIA */
  const isEmpty = React.useCallback((str) => {
    return str.length === 0 || !str.trim();
  }, []);

  //**CHECK CHANGE JUSTIFICATION */
  const Checkjustification = React.useCallback(() => {
    if (
      fields['reg_entrada'].value === 'folga' ||
      fields['reg_entrada'].value === 'férias' ||
      fields['reg_entrada'].value === 'atestado' ||
      fields['reg_entrada'].value === 'licença' ||
      fields['reg_entrada'].value === 'feriado'
    ) {
      return true;
    }
    return false;
  }, [fields]);

  //**TOGGLE MODAL FILTER */
  function handleFilter() {
    const filter = document.getElementById('filter');
    filter.classList.toggle('open');
  }

  //**RESET MODAL FORM */
  const handleModalFormReset = React.useCallback(() => {
    fields['entrada'].value = '08:30:00';
    fields['interval_start'].value = '00:00:00';
    fields['interval_back'].value = '00:00:00';
    fields['output'].value = '00:00:00';

    fields['reg_entrada'].value = '08:30:00';
    fields['reg_interval_start'].value = '00:00:00';
    fields['reg_interval_back'].value = '00:00:00';
    fields['reg_output'].value = '00:00:00';

    fields['username'].selectedIndex = '0';
    fields['reg_username'].selectedIndex = '0';
    fields['justification'].selectedIndex = '0';
    fields['work_status'].selectedIndex = '0';
  }, [fields]);

  //**CHANGE COLABORADOR */
  const handleUserChange = React.useCallback(
    (e) => {
      const filtered = [...e.target.children].filter((item) => (e.target.value === item.value ? item : ''));
      fields['onlyuser'].value = filtered[0].dataset.name;
    },
    [fields],
  );

  //**EXTRA CALCULO */
  const ExtraCalculator = React.useCallback(
    (total, time) => {
      let seconds = 0;
      let totalBase = 0;
      const DayInSeconds = 86400;

      if (Math.sign(time) < 0 && Math.sign(total) >= 0) {
        seconds = Math.abs(time);
        totalBase = total - seconds;

        if (totalBase >= DayInSeconds) {
          const day = Math.floor(totalBase / DayInSeconds);
          fields['totalhoras'].value = `${day}D ${converteToTime(totalBase)}`;
          return totalBase;
        }

        fields['totalhoras'].value = converteToTime(totalBase);
        return totalBase;
      } else if (Math.sign(time) < 0 && Math.sign(total) < 0) {
        seconds = Math.abs(time);
        totalBase = parseInt(`${Math.abs(total) + seconds}`, 10);

        if (totalBase >= DayInSeconds) {
          const day = Math.floor(totalBase / DayInSeconds);
          fields['totalhoras'].value = `-${day}D ${converteToTime(totalBase)}`;
          return totalBase;
        }

        fields['totalhoras'].value = converteToTime(parseInt(`-${totalBase}`, 10));
        return totalBase;
      } else {
        seconds = parseInt(time, 10);
        totalBase = total + seconds;

        if (totalBase >= DayInSeconds) {
          const day = Math.floor(totalBase / DayInSeconds);
          fields['totalhoras'].value = `${day}D ${converteToTime(totalBase)}`;
          return totalBase;
        }

        fields['totalhoras'].value = converteToTime(totalBase);
        return totalBase;
      }
    },
    [fields, converteToTime],
  );

  //**EXTRA CALCULO INICIAL */
  const handleExtra = React.useCallback(
    (data) => {
      if (!isEmpty(fields['colaborador'].value)) {
        let totalPositive = 0;
        let totaNegative = 0;

        data.forEach((item) => {
          if (item.arquivado) return false;
          const extra = parseInt(item.extra_in_seconds, 10);
          if (!isNaN(extra)) {
            if (extra > 600) {
              totalPositive = totalPositive + parseInt(item.extra_in_seconds, 10);
            }

            if (extra < -600) {
              const extraSeconds = Math.abs(extra);
              totaNegative = parseInt(`-${Math.abs(totaNegative) + extraSeconds}`, 10);
            }
          }
        });

        const totalExtra = totalPositive - Math.abs(totaNegative);
        ExtraCalculator(0, totalExtra);
      } else {
        toast.warning('Selecione um colaborador para total do extra..');
      }
    },
    [fields, isEmpty, ExtraCalculator],
  );

  //**CONFIGURA OS FILTERS */
  const getFilters = React.useCallback(() => {
    return new Promise((resolve) => {
      const filter = {};
      filter.dataInicial = fields['data_inicio'].value;
      filter.dataFinal = fields['data_final'].value;
      if (filter.dataInicial === 'NaN-NaN-NaN' || filter.dataFinal === 'NaN-NaN-NaN')
        return toast.error('Data invalida..');
      if (!isEmpty(fields['colaborador'].value)) filter.codigo = fields['colaborador'].value;

      filter.search = `&datainicial=${filter.dataInicial}&datafinal=${filter.dataFinal}`;
      if (fields['setor'].value.trim() !== '') {
        filter.search += `&setor=${fields['setor'].value}`;
      } else {
        if (filter.codigo) filter.search += `&codigo=${filter.codigo}`;
        if (fields['total_horas'].checked) filter.search += `&extra=true`;
      }
      resolve(filter.search);
    });
  }, [fields, isEmpty]);

  //**EXECUTA UMA REQUISIÇÃO PARA API  */
  const GetUrl = React.useCallback(async () => {
    const page = parseInt(fields['page'].value, 10);
    const limit = parseInt(fields['limit'].value, 10);
    const query = await getFilters();

    const response = await ajax({ url: `/timerecords?page=${page}&limit=${limit}${query}` });

    if (response.count > 0) {
      setDataSet(response);
      if (fields['total_horas'].checked) {
        handleExtra(response.rows);
        setCount(0);
        setPageActive(1);
        setLimitCount(0);
      } else {
        setCount(response.count);
        setPageActive(page);
        setLimitCount(Math.ceil(+response.count / limit));
      }
    }
  }, [fields, getFilters, handleExtra]);

  //**EXECUTA OS FILTERS */
  const handleSetFilter = React.useCallback(async () => {
    const filter = document.getElementById('filter');

    if (fields['setor_extra'].checked && fields['setor_text'].value.trim() !== '') {
      const dataInicial = fields['data_inicio'].value;
      const dataFinal = fields['data_final'].value;
      const setor = fields['setor_text'].value;

      if (dataInicial === 'NaN-NaN-NaN' || dataFinal === 'NaN-NaN-NaN') return toast.error('Data invalida..');

      let query = `?page=1&datainicial=${dataInicial}&datafinal=${dataFinal}`;
      if (setor.trim() !== '') query += `&setor=${setor}`;

      const response = await ajax({ url: `/timerecords/sumtimesheet${query}` });

      if (response.result.length === 0) {
        return toast.warning('Nenhum registro encontrado...');
      }

      if (response.result.length > 0) {
        setDataSetSector(response.result);
        setDataSet(null);
        setLimitCount(0);
        setCount(0);
      }

      filter.classList.toggle('open');
      return false;
    }

    setDataSetSector(null);
    if (isEmpty(fields['colaborador'].value) && fields['total_horas'].checked)
      return toast.warning('Selecione um colaborador para total do extra..');

    await GetUrl();
    filter.classList.toggle('open');
  }, [fields, GetUrl, isEmpty]);

  //**SUBMIT TIMESHEET UPDATE */
  const handleUpdateTimeSheet = React.useCallback(() => {
    setButtonSubmit(true);
    let params;
    const filter = document.getElementById('filter');
    if (Checkjustification()) {
      params = [
        { name: 'user_created', value: user.id },
        { name: 'timesheetId', value: timesheetId },
        { name: 'data', value: fields['data'].value },
        { name: 'entrada', value: fields['entrada'].value },
        { name: 'interval_start', value: fields['interval_start'].value },
        { name: 'interval_back', value: fields['interval_back'].value },
        { name: 'output', value: fields['output'].value },
        { name: 'work_status', value: fields['work_status'].value },
        { name: 'observation', value: fields['observation'].value },
      ];
    } else {
      params = [
        { name: 'user_created', value: user.id },
        { name: 'timesheetId', value: timesheetId },
        { name: 'data', value: fields['data'].value },
        { name: 'entrada', value: fields['entrada'].value },
        {
          name: 'interval_start',
          value: fields['interval_start'].value === '00:00:00' ? '' : fields['interval_start'].value,
        },
        {
          name: 'interval_back',
          value: fields['interval_back'].value === '00:00:00' ? '' : fields['interval_back'].value,
        },
        { name: 'output', value: fields['output'].value === '00:00:00' ? '' : fields['output'].value },
        { name: 'work_status', value: fields['work_status'].value },
        { name: 'observation', value: fields['observation'].value },
      ];
    }

    async function sendForm() {
      const body = await serialize(params);
      const response = await ajax({ url: '/timerecords/update', method: 'post', body, json: true });
      console.log(response);

      if (response.success) {
        toast.success('Ponto atualizado com sucesso!');
        handleSetFilter();
        filter.classList.toggle('open');
        setIsModal(false);
        setButtonSubmit(false);
        handleModalFormReset();
      } else {
        toast.success('Error contact o administardor!');
        console.log(response);
        setButtonSubmit(false);
      }
    }

    sendForm();
  }, [fields, timesheetId, user, handleSetFilter, Checkjustification, handleModalFormReset]);

  //**OPEN MODAL UPDATE */
  const handleEdit = React.useCallback(
    (e) => {
      setIsModal(true);
      const trow = e.target.closest('tr');
      setTimesheetId(trow.dataset.item);
      fields['username'].value = trow.cells[0].children[1].innerText;
      fields['data'].value = trow.cells[1].dataset.date.split('T')[0];
      fields['entrada'].value = trow.cells[2].dataset.item;
      fields['interval_start'].value = trow.cells[3].dataset.item;
      fields['interval_back'].value = trow.cells[4].dataset.item;
      fields['output'].value = trow.cells[5].dataset.item;
    },
    [fields],
  );

  const removeAction = React.useCallback(
    async (e) => {
      const trow = e.target.closest('tr');
      const idItem = trow.dataset.item;
      const response = await ajax({ url: '/timerecords/' + idItem + '/destroy', method: 'delete' });
      if (response.success === true) {   
        handleSetFilter()    
        return toast.success("Removido com sucesso");        
      }
  
      return toast.error('Ops erro ao remover...');
    },
    [handleSetFilter],
  );

  //**CLOSE MODAL UPDATE */
  function handleModalClose() {
    setIsModal(false);
  }

  //**RESETA OS FILTERS */
  const handleFilterReset = React.useCallback(() => {
    fields['data_inicio'].value = todayAddDay({ format: 'yyyy/mm/dd' });
    fields['data_final'].value = todayAddDay({ format: 'yyyy/mm/dd' });
    fields['setor'].value = '';
    fields['status'].checked = true;
    fields['total_horas'].checked = false;
    fields['colaborador'].selectedIndex = '0';
    fields['limit'].selectedIndex = '0';
  }, [fields, todayAddDay]);

  //**SUBMIT TIMESHEET REGISTER */
  const handleCreateTimeSheet = React.useCallback(() => {
    let params;
    if (fields['reg_username'].value === '') return toast.error('Selecione um colaborador..');
    const filter = document.getElementById('filter');
    setButtonSubmit(true);

    if (Checkjustification()) {
      params = [
        { name: 'user_created', value: user.id },
        { name: 'colaboradorId', value: fields['reg_username'].value },
        { name: 'data', value: fields['reg_data'].value },
        { name: 'entrada', value: fields['reg_entrada'].value },
        { name: 'interval_start', value: fields['reg_interval_start'].value },
        { name: 'interval_back', value: fields['reg_interval_back'].value },
        { name: 'output', value: fields['reg_output'].value },
        { name: 'justification', value: fields['justification'].value },
        { name: 'observation', value: fields['reg_observation'].value },
      ];
    } else {
      params = [
        { name: 'user_created', value: user.id },
        { name: 'colaboradorId', value: fields['reg_username'].value },
        { name: 'data', value: fields['reg_data'].value },
        { name: 'entrada', value: fields['reg_entrada'].value },
        {
          name: 'interval_start',
          value: fields['reg_interval_start'].value === '00:00:00' ? '' : fields['reg_interval_start'].value,
        },
        {
          name: 'interval_back',
          value: fields['reg_interval_back'].value === '00:00:00' ? '' : fields['reg_interval_back'].value,
        },
        { name: 'output', value: fields['reg_output'].value === '00:00:00' ? '' : fields['reg_output'].value },
        { name: 'justification', value: fields['justification'].value },
        { name: 'observation', value: fields['reg_observation'].value },
      ];
    }

    async function sendForm() {
      const body = await serialize(params);
      const response = await ajax({ url: '/timerecords/store', method: 'post', body, json: true });      

      if (response.success) {
        toast.success('Ponto registrado com sucesso!');
        filter.classList.toggle('open');
        handleSetFilter();
        setIsModalRegister(false);
        setButtonSubmit(false);
        handleModalFormReset();
      } else {
        if (response.message) {
          toast.error(response.message);
        } else {
          toast.success('Error contact o administardor!');
        }        
        setIsModalRegister(false);
        setButtonSubmit(false);
      }
    }

    sendForm();
  }, [fields, user, handleSetFilter, Checkjustification, handleModalFormReset]);

  //**OPEN MODAL REGISTER */
  const handleModalRegister = React.useCallback(() => {
    setIsModalRegister(true);
    const week = weekDay(fields['reg_data'].value);
    if (week === 'Sábado') {
      fields['interval_start'].value = 'Sábado';
      fields['interval_back'].value = 'Sábado';
      fields['reg_interval_start'].value = 'Sábado';
      fields['reg_interval_back'].value = 'Sábado';
    }
  }, [fields, weekDay]);

  //**CLOSE MODAL REGISTER */
  function handleModalRegisterClose() {
    setIsModalRegister(false);
  }

  const pageHistory = React.useCallback(
    (value) => {
      history.push({
        search: `?page=${value}`,
        page: value,
      });
    },
    [history],
  );

  //**Paginate onClick */
  const handlePaginate = React.useCallback(
    async (e) => {
      const el = e.target;

      if (el.matches('.next')) {
        const nextPage = parseInt(fields['page'].value + 1, 10);
        fields['page'].value = nextPage >= limitCount ? limitCount : nextPage;
        pageHistory(fields['page'].value);
        await GetUrl();
        return false;
      }

      if (el.matches('.prev')) {
        const prevPage = parseInt(fields['page'].value - 1, 10);
        fields['page'].value = prevPage < 2 ? 1 : prevPage;
        pageHistory(fields['page'].value);
        await GetUrl();
        return false;
      }

      if (el.matches('.firstpage')) {
        fields['page'].value = 1;
        pageHistory(1);
        await GetUrl();
        return false;
      }

      if (el.matches('.lastpage')) {
        fields['page'].value = parseInt(e.target.innerText, 10);
        pageHistory(parseInt(e.target.innerText, 10));
        await GetUrl();
        return false;
      }

      fields['page'].value = parseInt(e.target.innerText, 10);
      pageHistory(parseInt(e.target.innerText, 10));
      await GetUrl();
    },
    [fields, limitCount, pageHistory, GetUrl],
  );

  //**VERIFICA O DIA DA SEMANA*/
  const handleChangeDate = React.useCallback(
    (e) => {
      if (Checkjustification()) return false;
      const week = weekDay(e.target.value);
      if (week === 'Sábado') {
        fields['interval_start'].value = 'Sábado';
        fields['interval_back'].value = 'Sábado';
        fields['output'].value = '12:30:00';

        fields['reg_interval_start'].value = 'Sábado';
        fields['reg_interval_back'].value = 'Sábado';
        fields['reg_output'].value = '12:30:00';
      }
    },
    [fields, weekDay, Checkjustification],
  );

  //**EXECUTA AS JUSTIFICATIVAS */
  const handleChangeJustification = React.useCallback(
    (e) => {
      function FieldsChange(text) {
        fields['reg_entrada'].value = text;
        fields['reg_interval_start'].value = text;
        fields['reg_interval_back'].value = text;
        fields['reg_output'].value = text;

        fields['entrada'].value = text;
        fields['interval_start'].value = text;
        fields['interval_back'].value = text;
        fields['output'].value = text;
      }
      const cb = {
        ferias: () => {
          FieldsChange('férias');
        },
        folga: () => {
          FieldsChange('folga');
        },
        atestado: () => {
          FieldsChange('atestado');
        },
        licenca: () => {
          FieldsChange('licença');
        },
        liberado: () => {
          FieldsChange('liberado');
        },
        feriado: () => {
          FieldsChange('feriado');
        },
      };
      if (e.target.value === '') return false;
      cb[e.target.value]();
    },
    [fields],
  );

  const handlePrint = React.useCallback(() => {
    const dataInicial = fields['data_inicio'].value;
    const dataFinal = fields['data_final'].value;

    if (fields['setor_extra'].checked && fields['setor_text'].value.trim() !== '') {
      const setor = fields['setor_text'].value;

      let query = `?page=1&limit=999999999&datainicial=${dataInicial}&datafinal=${dataFinal}`;
      if (setor.trim() !== '') query += `&setor=${setor}`;

      window.location.href = '/ponto/report-setor' + query;
    } else {
      let search = `?page=1&limit=999999999&datainicial=${dataInicial}&datafinal=${dataFinal}`;
      if (fields['setor'].value.trim() !== '') {
        search += `&setor=${fields['setor'].value}`;
      } else {
        if (!isEmpty(fields['colaborador'].value)) search += `&codigo=${fields['colaborador'].value}`;
        if (fields['total_horas'].checked) search += `&extra=true`;
      }
      window.location.href = '/ponto/report' + search;
    }
  }, [fields, isEmpty]);

  React.useEffect(() => {
    setLoading(true);

    async function LoadingFields() {
      if (fields['entrada'] !== undefined) {
        Mask.timeSec(fields['entrada']);
        Mask.timeSec(fields['interval_start']);
        Mask.timeSec(fields['interval_back']);
        Mask.timeSec(fields['output']);

        Mask.timeSec(fields['reg_entrada']);
        Mask.timeSec(fields['reg_interval_start']);
        Mask.timeSec(fields['reg_interval_back']);
        Mask.timeSec(fields['reg_output']);

        const isUsers = await ajax({ url: '/users?status=' + fields['status'].value });
        setNotUsers(['Wilsinho Chequer', 'Admin Ponto']);
        setColaboradores(isUsers.rows);
        pageHistory(fields['page'].value);
        await GetUrl();
      }
    }

    if (loading) {
      setTimeout(() => {
        LoadingFields();
      }, 1000);
    }
  }, [fields, loading, pageHistory, GetUrl]);

  if (!loading) return <div></div>;

  return (
    <>
      <Content>
        <ContentContainer>
          <Row>
            <Container className="height-auto">
              <Flex className="shadow">
                <GroupButton>
                  <ButtonRow className="mini" src={MiniAdd} onClick={handleModalRegister} />
                  <ButtonRow className="mini" src={MiniFilter} onClick={handleFilter} />
                  <ButtonRow className="mini" src={MiniPrint} onClick={handlePrint} />
                </GroupButton>
                <Row>
                  <Input col={4} xs={8} text="Colaborador.." name="onlyuser" readOnly={true} />
                  <Input
                    col={4}
                    xs={4}
                    text="Total em horas.."
                    name="totalhoras"
                    inputMode="numeric"
                    defaultValue="00:00:00"
                    readOnly={true}
                  />
                </Row>
              </Flex>
            </Container>
          </Row>
          <TableContainer>
            {dataSetSector ? (
              <TableSector dataSet={dataSetSector} />
            ) : (
              <TableRender dataSet={dataSet} editAction={handleEdit} removeAction={removeAction} />
            )}
          </TableContainer>
          {limitCount > 1 && (
            <Paginate count={count} page={pageActive} limitCount={limitCount} onClick={handlePaginate} />
          )}
          <Filter>
            <header>
              <span>
                <FiFilter size={22} /> Filtros
              </span>
              <FiXSquare size={30} onClick={handleFilter} />
            </header>
            <div style={{ marginTop: '1rem' }}>
              <Input className="hidden" type="hidden" name="page" value={1} />
              <Input
                type="date"
                col={12}
                defaultValue={todayAddDay({ format: 'yyyy/mm/dd' })}
                text="Data inicial"
                name="data_inicio"
              />
              <Input
                type="date"
                col={12}
                defaultValue={todayAddDay({ format: 'yyyy/mm/dd' })}
                text="Data final"
                name="data_final"
              />

              <Select label="Colaborador.." col={12} name="colaborador" onChange={handleUserChange}>
                <option value="" style={{ display: 'none' }}>
                  Selecione...
                </option>
                {colaboradores &&
                  colaboradores.map(
                    (item) =>
                      isNotUsers.indexOf(item.name) === -1 && (
                        <option key={item.id} value={item.id} data-name={item.name}>
                          {item.name}
                        </option>
                      ),
                  )}
              </Select>

              <Input type="text" col={12} text="Filtrar por setor" name="setor" />

              <Select label="Itens por pagina.." col={12} name="limit">
                <option value={100} style={{ display: 'none' }}>
                  100
                </option>
                <option value={20}>20</option>
                <option value={40}>40</option>
                <option value={80}>50</option>
                <option value={100}>100</option>
                <option value={1000}>1000</option>
              </Select>
            </div>
            <main className="filter-inputs">
              <GroupList>
                <h2>Setor com (total extra)</h2>
                <Input className="checkbox" type="checkbox" name="setor_extra" />
                <Input name="setor_text" />
              </GroupList>
              <CheckList>
                <FilterLabel
                  col={12}
                  type="checkbox"
                  name="status"
                  value={1}
                  text="Colaborador ativo"
                  defaultChecked={true}
                />
              </CheckList>
              <CheckList>
                <FilterLabel col={12} type="checkbox" name="total_horas" text="Total em horas" defaultChecked={false} />
              </CheckList>
              <button style={{ marginTop: '1rem' }} onClick={handleSetFilter}>
                <FiFilter size={22} />
                <span>Aplicar filtro</span>
              </button>
              <button className="reset" onClick={handleFilterReset}>
                <FiFilter size={22} />
                <span>Resetar filtro</span>
              </button>
            </main>
          </Filter>
        </ContentContainer>
      </Content>
      <Modal
        title="Atualizar ponto"
        close={handleModalClose}
        is={isModal}
        lgW="25%"
        lgH="auto"
        mdW="40%"
        mdH="60%"
        xsW="90%"
        xsH="80%"
      >
        <div className="content__main">
          <ContentModal>
            <ModalRow className="green-light">
              <FaUserCheck color="#2885fd" />
              <Input col={12} text="Colaborador.." name="username" readOnly={true} />
            </ModalRow>
            <ModalRow className="green-light">
              <FaCalendarAlt className="datadia" />
              <Input
                type="date"
                col={12}
                defaultValue={todayAddDay({ format: 'yyyy/mm/dd' })}
                text="Dia"
                name="data"
                onChange={handleChangeDate}
              />
            </ModalRow>
            <ModalRow className="green-light">
              <Svg icon="icon-entrada" />
              <Input col={3} text="Entrada.." name="entrada" inputMode="numeric" defaultValue="08:30:00" />
            </ModalRow>
            <ModalRow className="color-indigo">
              <Svg icon="icon-intsaida" />
              <Input col={3} text="A.Saida.." name="interval_start" inputMode="numeric" defaultValue="12:00:00" />
            </ModalRow>
            <ModalRow className="color-orange">
              <Svg icon="icon-intvolta" />
              <Input col={3} text="A.Volta.." name="interval_back" inputMode="numeric" defaultValue="14:00:00" />
            </ModalRow>
            <ModalRow className="color-teal">
              <Svg icon="icon-saida" />
              <Input col={2} text="Saida.." name="output" inputMode="numeric" defaultValue="18:30:00" />
            </ModalRow>
            <Select
              label="Folga, Atestado, Licença, Ferias.."
              col={12}
              name="work_status"
              onChange={handleChangeJustification}
            >
              <option value="" style={{ display: 'none' }}>
                Selecione...
              </option>
              <option value="ferias">Férias</option>
              <option value="folga">Folga</option>
              <option value="atestado">Atestado</option>
              <option value="licenca">Licença</option>
              <option value="liberado">Liberado</option>
              <option value="feriado">Feriado</option>
            </Select>
            <TextArea
              col={12}
              text="Observações.."
              name="observation"
              wrap="off"
              rows={1}
              className="noResize width99"
              style={{ marginBottom: '0' }}
            />
            <ModalAction>
              <button style={{ marginTop: '1rem' }} onClick={handleUpdateTimeSheet} disabled={buttonSubmit}>
                <FaSave size={22} />
                <span>Atualizar</span>
              </button>
              <button className="btn-danger" onClick={handleModalFormReset} disabled={buttonSubmit}>
                <FaSave size={22} />
                <span>Reset</span>
              </button>
            </ModalAction>
          </ContentModal>
        </div>
      </Modal>
      <Modal
        title="Registrar ponto"
        close={handleModalRegisterClose}
        is={isModalRegister}
        lgW="25%"
        lgH="auto"
        mdW="40%"
        mdH="60%"
        xsW="90%"
        xsH="80%"
      >
        <div className="content__main">
          <ContentModal>
            <ModalRow className="green-light">
              <FaUserCheck color="#2885fd" />
              <Select label="Colaborador.." name="reg_username">
                <option value="" style={{ display: 'none' }}>
                  Selecione...
                </option>
                {colaboradores &&
                  colaboradores.map(
                    (item) =>
                      isNotUsers.indexOf(item.name) === -1 && (
                        <option key={item.id} value={item.id} data-name={item.name}>
                          {splitName(item.name, 2)}
                        </option>
                      ),
                  )}
              </Select>
            </ModalRow>

            <ModalRow className="green-light">
              <FaCalendarAlt className="datadia" />
              <Input
                type="date"
                col={12}
                defaultValue={todayAddDay({ format: 'yyyy/mm/dd' })}
                text="Dia"
                name="reg_data"
                onChange={handleChangeDate}
              />
            </ModalRow>
            <ModalRow className="green-light">
              <Svg icon="icon-entrada" />
              <Input col={3} text="Entrada.." name="reg_entrada" inputMode="numeric" defaultValue="08:30:00" />
            </ModalRow>
            <ModalRow className="color-indigo">
              <Svg icon="icon-intsaida" />
              <Input col={3} text="A.Saida.." name="reg_interval_start" inputMode="numeric" defaultValue="00:00:00" />
            </ModalRow>
            <ModalRow className="color-orange">
              <Svg icon="icon-intvolta" />
              <Input col={3} text="A.Volta.." name="reg_interval_back" inputMode="numeric" defaultValue="00:00:00" />
            </ModalRow>
            <ModalRow className="color-teal">
              <Svg icon="icon-saida" />
              <Input col={2} text="Saida.." name="reg_output" inputMode="numeric" defaultValue="00:00:00" />
            </ModalRow>
            <Select
              label="Folga, Atestado, Licença, Ferias.."
              col={12}
              name="justification"
              onChange={handleChangeJustification}
            >
              <option value="">Selecione...</option>
              <option value="ferias">Férias</option>
              <option value="folga">Folga</option>
              <option value="atestado">Atestado</option>
              <option value="licenca">Licença</option>
              <option value="liberado">Liberado</option>
              <option value="feriado">Feriado</option>
            </Select>
            <TextArea
              col={12}
              text="Observações.."
              name="reg_observation"
              wrap="off"
              rows={1}
              className="noResize width99"
              style={{ marginBottom: '0' }}
            />
            <ModalAction>
              <button style={{ marginTop: '1rem' }} onClick={handleCreateTimeSheet} disabled={buttonSubmit}>
                <FaSave size={22} />
                <span>Registrar</span>
              </button>
              <button className="btn-danger" onClick={handleModalFormReset} disabled={buttonSubmit}>
                <FaSave size={22} />
                <span>Reset</span>
              </button>
            </ModalAction>
          </ContentModal>
        </div>
      </Modal>
    </>
  );
}

// const GroupList = styled.div`
//     display: flex;
//     flex-wrap: wrap;
//     align-items: center;
//     padding: 1rem 0.3rem;
//     margin-bottom: 1rem;
//     border: 1px solid #e3d3d3;
//     border-radius: 4px;
//     overflow: hidden;

//     h2 {
//         width: 100%;
//         margin-bottom: 5px;
//         font-size: 0.7rem;
//         color: #5264ae;
//     }

//     input[type=checkbox] {
//         width: 1.5rem;
//         height: 1.5rem;
//         cursor: pointer;
//     }

//     div {
//         margin-bottom: initial;
//         flex: 1;
//     }

//     & .checkbox {
//         flex: initial;
//         padding: initial;
//     }
// `;

const TableContainer = styled.div`
  flex: 1;
  overflow: auto;
  scrollbar-width: thin;
  padding: 0.5rem;
`;

const ModalAction = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0;

  button {
    width: 100%;
    color: #f6f8ff;
    background-color: #2885fd;
    display: flex;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 1rem;
    padding: 1rem;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    font-weight: 500;
    padding-right: 1rem;

    &:hover {
      opacity: 0.9;
    }

    &:disabled {
      background-color: #eee;
      color: #999;
      cursor: not-allowed;
    }

    svg {
      margin-right: 10px;
    }

    &.btn-danger {
      background-color: #ff6767;
    }
  }
`;

const ModalRow = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0.8rem;

  svg {
    width: 2rem;
    height: 2rem;
    transform: translateY(-1.05rem);

    &.datadia {
      width: 1.8rem;
      height: 1.8rem;
      color: tomato !important;
      fill: tomato !important;
    }
  }

  div {
    width: calc(100% - 2rem);
  }
`;

const ContentModal = styled.div`
  padding: 15px 10px;
  margin: 1rem;
  border: 1px solid #eee;
  border-radius: 5px;
  overflow: auto;
`;

const CheckList = styled.div`
  h2 {
    width: 100%;
    padding: 0 1rem;
    font-size: 1rem;
    color: #7483be;
  }

  label {
    padding: 5px !important;
    margin-bottom: initial !important;
  }
`;

const GroupButton = styled.div`
  padding: 0 1rem;
  display: flex;
  align-items: center;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
`;

const Filter = styled(({ children, ...props }) => (
  <div id="filter" className={props.className} onClick={props.onClick}>
    {children}
  </div>
))`
  position: absolute;
  top: 8px;
  bottom: 8px;
  width: 22rem;
  background-color: #fff;
  box-shadow: -1px 2px 10px rgba(0, 0, 100, 0.3);
  border-radius: 8px;
  right: -30rem;
  transition: right 0.4s ease-in-out;

  @media only screen and (max-width: 37.5em) {
    width: calc(100% - 18px);
  }

  display: flex;
  flex-direction: column;
  overflow: hidden;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    background-color: #7f808a;
    color: #fff;
    margin: 5px;
    border-radius: 4px;
    font-size: 1.2rem;
    user-select: none;
    & span {
      display: flex;
      align-items: center;
    }
    & svg {
      /* margin-right: 1rem; */
    }
  }

  main {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 5px;
    overflow: auto;

    & label {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      padding: 5px 1rem;
      cursor: pointer;
      font-size: 0.9rem;
      user-select: none;

      &.column {
        flex-direction: column;
        align-items: flex-start;
      }

      & input[type='text'] {
        width: 100%;
        height: 2.5rem;
        border-radius: 4px;
        border: 1px solid #cecece;
        padding: 0 1rem;
        font-size: 1.1rem;
        font-family: inherit;
      }

      & input[type='radio'] {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 1rem;
      }

      & span {
        margin-left: 5px;
      }
    }

    & button {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      padding: 1rem;
      cursor: pointer;
      border: none;
      border-radius: 4px;
      font-size: 1rem;
      font-weight: 500;
      color: #f6f8ff;
      background-color: #2885fd;

      & span {
        flex: 1;
      }

      &.reset {
        background-color: #ff5f5f;
      }
    }
  }

  &.open {
    right: 8px;
  }
`;

const GroupList = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 1rem 0.3rem;
  margin-bottom: 1rem;
  border: 1px solid #e3d3d3;
  border-radius: 4px;
  overflow: hidden;

  h2 {
    width: 100%;
    margin-bottom: 5px;
    font-size: 0.7rem;
    color: #5264ae;
  }

  input[type='checkbox'] {
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
  }

  div {
    margin-bottom: initial;
    flex: 1;
  }

  & .checkbox {
    flex: initial;
    padding: initial;
  }
`;
